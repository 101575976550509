<template>
  <div>
    <FilterBox @on-change="change" />
    <Box :title="$t('d8a7e67')">
      <div v-if="!isPersonEnterprise" slot="side">
        <el-button class="btn" icon="el-icon-s-promotion" @click="jumpRelease">
          <span>{{ $t('3f8bf39') }}</span>
        </el-button>
      </div>
      <div v-loading="loading">
        <OrderItem
          v-for="item in list"
          :key="item.id"
          type="list"
          :data="item"
        />
        <Empty v-if="!list.length" type="serviceCoop" />
      </div>
      <div class="pager">
        <el-pagination
          :current-page="query.page"
          :page-size="query.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getList"
        />
      </div>
    </Box>
  </div>
</template>

<script>
import FilterBox from './filter'
import Box from '@/components/Box'
import Api from '@/api/service'
import OrderItem from './OrderItem'
import Empty from '@/components/Empty/Factory'

import { isPersonEnterprise } from '@/utils/jurisdiction'
export default {
  components: {
    FilterBox,
    Box,
    OrderItem,
    Empty,
  },
  data() {
    return {
      loading: false,
      list: [],
      total: 0,

      query: {
        status: '',
        businessTypeId: '',
        serviceCountry: '',
        page: 1,
        size: 8,
      },
    }
  },
  mounted() {
    this.getList(1)
  },
  methods: {
    isPersonEnterprise() {
      return isPersonEnterprise()
    },
    getList(page) {
      this.loading = true

      Api.orderList({ ...this.query, page })
        .then((res) => {
          this.query.page = page
          this.list = res.content
          this.total = res.page.total
        })
        .catch((err) => {
          console.log('err', err)
          // this.$message.error('发生错误, 请重试');
        })
        .then((res) => {
          this.loading = false
        })
    },
    change(val) {
      this.query = { ...this.query, ...val }
      this.getList(1)
    },
    jumpRelease() {
      this.$router.push('/content/service/release')
    },
  },
}
</script>

<style lang="less" scoped>
.btn {
  padding: 0;
  height: 32px;
  width: 120px;
  color: #00a4ff;
  border-color: #00a4ff;
}

.empty {
  padding: 30px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}

.pager {
  padding: 20px 20px 0;
  text-align: right;
}
</style>
