<template>
  <section class="serv-item">
    <template v-for="item in map">
      <div
        v-if="data.status === item.key"
        :key="item.key"
        class="tag"
        :class="item.tag"
      >
        {{ item.name }}
      </div>
    </template>

    <div v-if="type === 'detail'" class="steps">
      <div
        v-for="(item, idx) in steps"
        :key="item.name"
        class="step"
        :class="{ active: idx <= step }"
      >
        <span class="title">{{ item.name }}</span>
        <span class="sub-title">{{ item.date }}</span>
      </div>
    </div>

    <div class="desc">
      <el-row>
        <el-col :span="12">
          <DescItem :label="$t('9c1b456')">{{ data.title | textFilter }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('5cb0761')">{{ data.serviceCountry | textFilter }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('c2e2895')">{{ data.businessType | textFilter }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('9da0738')">{{ data.code | textFilter }}</DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('122cefe')">
            <div class="cont">
              {{ data.createTime | date('YYYY-MM-DD') | textFilter }}
            </div>
          </DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('5bfb52e')">
            {{ data.email | textFilter }}
          </DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('db8d921')">
            {{ data.servicePhone | textFilter }}
          </DescItem>
        </el-col>
      </el-row>
    </div>

    <i class="line"></i>

    <div class="desc">
      <el-row>
        <el-col :span="12">
          <DescItem :label="$t('3d6654a')">
            {{ data.name | textFilter }}
          </DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('8630098')">
            {{ data.contactPerson | textFilter }}
          </DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('46da118')">
            {{ data.contactPhone | textFilter }}
          </DescItem>
        </el-col>
        <el-col :span="12">
          <DescItem :label="$t('d3bcf66')">
            {{ data.serviceNo | textFilter }}
          </DescItem>
        </el-col>
        <el-col :span="24">
          <DescItem :label="$t('45b6fdb')">
            {{ data.time | date('YYYY-MM-DD HH:mm:ss') | textFilter }}
          </DescItem>
        </el-col>
        <el-col :span="24">
          <DescItem :label="$t('12cf38e')">
            {{ data.content | textFilter }}
          </DescItem>
        </el-col>
      </el-row>
      <!-- <div class="item">
        <div class="label">联系邮箱：</div>
        <div class="cont">
          {{ data.email | textFilter }}
        </div>
      </div> -->
    </div>

    <footer v-if="type === 'list'" class="foot">
      <div class="more" @click="jumpDetail(data.id)">
        {{ $t('696c3e0') }} <i class="icon"></i>
      </div>
    </footer>
  </section>
</template>

<script>
// import Api from '@/api/service'
// import dayjs from 'dayjs'
import { ORDER_STATUS } from '@/utils/constants'
import DescItem from '@/components/Descriptions/Item'

export default {
  components: {
    DescItem,
  },
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      },
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      map: ORDER_STATUS,
    }
  },
  computed: {
    step: function() {
      return this.map.findIndex((n) => n.key === this.data.status)
    },
    steps: function() {
      if (this.data.status === 'CANCEL') {
        return [
          { name: this.$t('52b1e86'), date: this.data.time },
          { name: this.$t('264e254'), date: this.data.cancelTime },
        ]
      } else {
        return [
          { name: this.$t('52b1e86'), date: this.data.time },
          { name: this.$t('92c8446'), date: this.data.communicationTime },
          { name: this.$t('6bb96c5'), date: this.data.startTime },
          { name: this.$t('c3c076c'), date: this.data.endTime },
        ]
      }
    },
  },
  methods: {
    jumpDetail(id) {
      this.$router.push(`/content/service/cooperationDetail?id=${id}`)
    },
  },
}
</script>

<style lang="less" scoped>
.serv-item {
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  padding-right: 30px;
  margin-bottom: 20px;
  padding-bottom: 16px;

  .steps {
    margin-top: 30px;
    margin-left: 30px;
    height: 114px;
    background: #f5fbff;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;

    .step {
      position: relative;
      flex: 1;
      padding-top: 46px;

      &::before {
        content: '';
        z-index: 5;
        position: absolute;
        top: 20px - 4;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #b6bbbe;
        border: 4px solid #f5fbff;
        transform: translate(-50%, 0);
      }

      &::after {
        content: '';
        position: absolute;
        top: 20px + 4px;
        right: 50%;
        height: 2px;
        width: 100%;
        background: #e2e8ec;
      }

      &:first-child::after {
        display: none;
      }

      &.active {
        &::before {
          background: #00a4ff;
        }
        &::after {
          background: #00a4ff;
        }

        .title,
        .sub-title {
          color: #333;
        }
      }
    }

    .title {
      display: block;
      text-align: center;
      font-size: 14px;
      color: #666666;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .sub-title {
      display: block;
      text-align: center;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
    }
  }

  .tag {
    display: inline-block;
    margin: 20px 0;
    //width: 114px;
    padding: 0 30px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 0 100px 100px 0;
    font-size: 18px;
    font-weight: 500;
    background: #f2f2f2;
    color: #c3c3c3;

    &-warning {
      background: rgba(#faad14, 0.07);
      color: #faad14;
    }
    &-info {
      background: rgba(#00a4ff, 0.07);
      color: #00a4ff;
    }
    &-success {
      background: rgba(#51c518, 0.07);
      color: #51c518;
    }
    &-danger {
      background: rgba(#f5222d, 0.07);
      color: #f5222d;
    }
  }

  .actions {
    position: absolute;
    top: 30px;
    right: 30px;

    i {
      font-size: 16px;
      color: #666;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .line {
    margin: 14px 30px 30px;
    display: block;
    border-bottom: 1px dashed #eee;
  }

  .desc {
    display: flex;
    flex-flow: row wrap;
    padding: 0 30px;
    .item {
      width: 50%;
      display: flex;
      padding-bottom: 14px;
      font-size: 14px;
      line-height: 24px;
      color: #333333;

      &-single {
        width: 100%;
      }
    }

    .label {
      width: 100px;
      text-align: right;
      color: #999999;
    }

    .cont {
      min-width: 0;
      flex: 1;
    }
  }

  .foot {
    padding: 10px 0;
    display: flex;
    justify-content: center;

    .more {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      color: #00a4ff;
      cursor: pointer;
    }

    .icon {
      position: relative;
      display: block;
      width: 12px;
      height: 12px;
      border: 1px solid #00a4ff;
      border-radius: 50%;
      margin-left: 8px;
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        margin-left: -1px;
        width: 4px;
        height: 4px;
        transform: translate(-50%, -50%) rotate(45deg);
        border-top: 1px solid #00a4ff;
        border-right: 1px solid #00a4ff;
        // border-radius: 2px;
      }
    }
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
