<template>
  <Box>
    <div slot="header"></div>
    <el-form label-width="110px" label-suffix="：" :model="query" :label-position="labelPosition">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('5d95804')">
            <el-select
              v-model="query.status"
              placeholder="请选择服务状态"
              style="width: 280px"
            >
              <el-option value="" :label="$t('8224500')" />
              <el-option
                v-for="item in status"
                :key="item.key"
                :value="item.key"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :style="{'text-align': isInZh ? 'right' : 'left'}">
          <el-form-item :label="$t('5cb0761')">
            <el-select
              v-model="query.serviceCountry"
              :placeholder="$t('dbcf9be')"
              style="width: 280px"
            >
              <el-option value="" :label="$t('8224500')" />
              <el-option
                v-for="item in countrys"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('c2e2895')">
            <el-select
              v-model="query.businessTypeId"
              :placeholder="$t('6fd5fc3')"
              style="width: 280px"
            >
              <el-option value="" :label="$t('8224500')" />
              <el-option
                v-for="item in fieldOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :style="{'text-align': isInZh ? 'right' : 'left', 'margin-top': isInZh ? '0px' : '48px'}">
          <el-button type="primary" @click="change('submit')">{{ $t('79dc812') }}</el-button>
          <el-button @click="change('reset')">{{ $t('1c4056e') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </Box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Box from '@/components/Box'
import ApiCom from '@/api/common'
import { ORDER_STATUS, SERVICE_TYPE } from '@/utils/constants'

const initQuery = () => ({
  status: '',
  businessTypeId: '',
  serviceCountry: '',
})

export default {
  components: {
    Box,
  },
  data() {
    return {
      countrys: [],
      types: SERVICE_TYPE,
      status: ORDER_STATUS,
      query: initQuery(),
      labelPosition: localStorage.getItem("lang") === "zh" ? "right" : "top",
      isInZh: localStorage.getItem("lang") === "zh"
    }
  },
  computed: {
    ...mapGetters(['dictionaries']),
    fieldOptions() {
      const dicts = this.dictionaries
      const dic1 = dicts['TRANSLATION_BUSINESS_TYPE'] || {}
      const dic2 = dicts['BUSINESS_TYPE'] || {}
      const dic = [...(dic1.list || []), ...(dic2.list || [])]
      return dic
    },
  },
  beforeUpdate() {
    this.labelPosition = localStorage.getItem("lang") === "zh" ? "right" : "top"
    this.isInZh = localStorage.getItem("lang") === "zh"
  },
  mounted() {
    this.getCountry()
    this.getDictionary({ type: 'BUSINESS_TYPE' })
    this.getDictionary({ type: 'TRANSLATION_BUSINESS_TYPE' })
    // this.getTypes()
  },
  methods: {
    ...mapActions(['getDictionary']),
    getCountry() {
      ApiCom.getDictionaryList({ type: 'COUNTRY' }).then((res) => {
        this.countrys = res.content
      })
    },
    // getTypes() {
    //   ApiCom.getDictionaryList({ type: 'BUSINESS_TYPE' }).then((res) => {
    //     this.types = res.content
    //   })
    // },
    change(type) {
      if (type === 'reset') {
        this.query = initQuery()
      }

      this.$emit('on-change', { ...this.query }, type)
    },
  },
}
</script>
